.wrapper {
  text-decoration: none;
  width: 100%;
}

/* tablet + view */
@media (min-width: 840px) {
  .wrapper:hover {
    display: inline-block;
    transform: translateY(-10px);
    cursor: pointer;
    transition: 0.3s;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: #1B1E32;
  color: white;
  padding: 35px 30px;
  margin-bottom: 10px;
}

.tech {
  margin: 0;
  letter-spacing: 1px;
  color: #FD4370;
  font-weight: 700;
}

.title {
  margin: 8px 0px;
  letter-spacing: 2px;
}

.descr {
  margin: 0;
  letter-spacing: 1px;
}

.date {
  margin: 0;
  margin-top: 10px;
  letter-spacing: 1px;
  color: #676a7b;
}

/* Animation */
@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }
    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }
    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
