.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
}

.footerLinks {
  display: flex;
  margin-left: 35px;
}

.footerLinks a {
  display: block;
  margin-right: 40px;
  text-decoration: none;
  color: #676a7b;
}

.footerLinks a:hover {
  transform: translateY(-5px);
  cursor: pointer;
  transition: 0.3s;
}

.linkText {
  margin-left: 10px;
  margin-right: 5px;
}

.cred {
  color: #676a7b;
  margin-right: 35px;
}

@media (max-width: 840px) {
  .container {
    position: relative;
    overflow-x: hidden;
    height: 100%;
    display: block;
    margin-top: -5px;
  }

  .footerLinks {
    display: block;
    margin-left: 35px;
  }

  .footerLink {
    margin-top: 5px;
  }

  .cred {
    display: block;
    margin-left: 35px;
    margin-top: 10px;
  }
}
