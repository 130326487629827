.container {
  display: flex;
  height: 100vh;
  align-items: center;
}

.intro {
  flex: 3;
  padding: 0px 35px;
}

@media (max-width: 840px) {
  .container {
    display: block;
    height: 100%;
  }
}
