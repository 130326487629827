.wrapper {
  height: 100vh;
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  overflow-y: scroll;
  overflow-x: hidden;
}

/* for custom scrollbar */
/* tablet + view */
@media (min-width: 840px) {
  html {
    --scrollbarBG: #141628;
    --thumbBG: #FD4370;
  }

  .container::-webkit-scrollbar {
    width: 11px;
  }

  .container {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }

  .container::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  .container::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}

/* mobile view */
@media (max-width: 840px) {
  .wrapper {
    height: 100%;
  }

  .container {
    margin: 35px 0;
  }
}
