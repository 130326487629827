.container {
  color: white;
}

.descr {
  width: 80%;
  line-height: 25px;
}

.highlight {
  color: #FD4370;
  font-weight: 700;
}

@media (max-width: 840px) {
  .container {
    margin-top: 35px;
  }

  .descr {
    width: 100%;
  }
}
