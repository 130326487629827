.container {
  color: white;
  margin: auto;
}

.navGroup {
  display: flex;
  align-items: center;
  letter-spacing: 1px;
}

.link {
  text-decoration: none;
  color: white;
  margin: 9px 0;
}

.sectionLine {
  border: 1px solid white;
  height: 0px;
  width: 30px;
  margin-right: 15px;
}

.currentSection {
  width: 50px;
  border: 2px solid #FD4370;
}

.navGroup:hover {
  transform: translateY(-5px);
  cursor: pointer;
  transition: 0.3s;
}
